import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { CurrentUserService } from 'app/service/current-user.service';
import { ListIncidentService } from 'app/service/list-incident-service/list-incident.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { SharedService } from 'app/service/shared-service/shared.service';
import { CookieStoreService } from 'app/service/cookieService/cookieService.service';
import { UtilisateurService } from 'app/service/utilisateur-service/utilisateur.service';
import { QualifIncidentTachesComponent } from 'app/taches/qualif-incident-taches/qualif-incident-taches/qualif-incident-taches.component';
import { QualifierTraitementTachesComponent } from 'app/taches/qualifier-traitement-taches/qualifier-traitement-taches/qualifier-traitement-taches.component';
import { AjouterInterventionComponent } from 'app/taches/traiter-incident-taches/traiter-incident-taches/ajouter-intervention.component';
import * as moment from "moment";
import { InterventionService } from 'app/service/intervention/intervention.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AjouterIncidentComponent } from '../ajouter-incident/ajouter-incident.component';
import { VerifierIncidentComponent } from '../verifier-incident/verifier-incident.component';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Objets par page';

  return customPaginatorIntl;
}

@Component({
  selector: 'ms-fiche-incident',
  templateUrl: './fiche-incident.component.html',
  styleUrls: ['./fiche-incident.component.scss']
})
export class FicheIncidentComponent implements OnInit, AfterViewInit {
  userItem;
  idIncident: any;
  isLoading: boolean = false;
  incident: any;
  code_statut_incident : string;
  localLattitude: number;
  localLongitude: number;
  zoom = 13;
  projectGallaryConfig = {
    "slidesToShow": 2,
    "fade": false,
    "slidesToScroll": 1,
    "arrows": true,
    "autoplay": false,
    "autoplaySpeed": 3000
  };
  ListHistorique = [];
  cle_incident
  id_residence: any;
  subscription: any;
  role_user: any;
  liste_historique: any = [];
  etapes = [
    { name: 'Création', code: 'CREE', number : "01"},
    { name: 'Qualification', code:'QUAL', number : "01"},
    { name: 'Planification', code: 'PLAN', number : "01"},
    { name: 'Intervention', code:'REAL', number : "01"},
    { name: 'Verification', code:'CLOT', number : "01"},
  ]
  listeEtapes: any;
  listIntervention: any;

  constructor(private pageTitleService: PageTitleService,
    private _currentUser        : CurrentUserService    ,
    private listincidentService : ListIncidentService   ,
    private formBuilder         : FormBuilder           ,
    private route               : ActivatedRoute        ,
    public dialog               : MatDialog             ,
    private utilisateurService  : UtilisateurService    ,
    private cookies: CookieStoreService                 ,
    public sharedService : SharedService                ,
    private interventionService: InterventionService
    ) {
      this.userItem   = this._currentUser.getRoleCurrentUser();
      this.idIncident = this.route.snapshot.paramMap.get('id_incident');
    }
  ngAfterViewInit(): void {
    // this.utilisateurService.getRoleUserPerResidence({id_residence : this.id_residence}).subscribe(value => {
    //   this.role_user = value
    // })
  }

  ngOnInit(): void {
    setTimeout(()=>{
			this.pageTitleService.setTitle("Fiche Incident");
		},0);

    this.id_residence = this.cookies.getTOKEN("id_residence");
    this.subscription = this.sharedService.getSelectedIdResidence().subscribe(value => {
      this.id_residence = value;
      this.getIncident();
    }); 
    this.getIncident();
    this.getHistoriqueIncident();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  getIncident() {
    this.isLoading = true;
    let body = {
      "id_residence" : this.id_residence
    };
    this.listincidentService.getFicheIncident(this.idIncident,body)
      .subscribe((res: any) => {
        this.ListHistorique = res.historique;
        this.incident = res;

        this.getListIntervention();

        this.isLoading = false;
      }, err => {
        this.showMessageError();
      })
  }
  etapes_code = {
    0: 'CREE', 
    1: 'QUAL', 
    2: 'PLAN', 
    3: 'REAL', 
    4: 'CLOT'
  }
  getHistoriqueIncident() {
    const body = {
      id_residence: this.id_residence
    }
    this.listincidentService.getHistoriqueIncident(this.idIncident, body).subscribe((result) => {
      this.liste_historique = result.hitorique;
      this.getEtape();
    }, (error) => {
      this.showMessageError();
    })
  }
  showMessageError() {
    Swal.fire({
      icon: 'error',
      title: `Une erreur est survenue`,
      text: 'Veuillez réessayer plus tard',
      showConfirmButton: false,
      heightAuto: false,
      timer: 2500
    });
  }

  resetData(event) {
    if (event == true)
      this.getIncident()
  }

  getEtape() {
    this.listeEtapes = [];
    let body = {
      type_parametrages: "COET",
    };
    this.listincidentService.getStatut(body).subscribe((res: any) => {
      this.listeEtapes = res.parametrage;

      let annuler_etape = this.liste_historique.find(hist => hist.code_statut_incident == "ANNU")

      this.listeEtapes.forEach((etape : any) => {
        etape["data"] = this.liste_historique.find(hist => hist.code_etape == etape.code_parametrage) || null
        if (annuler_etape?.code_etape === etape.code_parametrage) {
          etape["data"] = annuler_etape
        }
      });
      console.log('etapes : ',this.listeEtapes);
      
      
    },(error) => {
      console.error("Error fetching data:", error);
    });
  }

  changeTab($event){
    this.isLoading = true;
  }

  getListIntervention() {
    this.isLoading = true;
    this.listIntervention = []
    
    let body = {
      "id_residence" : this.id_residence,
      "id_origin" : this.incident?.id_incident,
      "code_origin_intervention" : "INCI",
    };
    
    this.interventionService.getListIntervention(9999, 0,body)
    .subscribe((res: any) => {
        this.listIntervention = res.listIntervention;
        this.isLoading = false;
    }, err => {
        this.showMessageError();
        this.isLoading = false;
    })
  }

  openQualifDialog(){
    const dialogRef = this.dialog.open(QualifIncidentTachesComponent, {
			width: '800px',
			autoFocus: false,
      maxHeight: '85vh'
		});
    dialogRef.componentInstance.cle_incident = this.incident.cle_incident;
    dialogRef.componentInstance.id_incident = this.incident.id_incident;
    dialogRef.componentInstance.id_residence = this.incident.id_residence
    dialogRef.componentInstance.code_categorie_prin = this.incident.code_categorie_prin
    dialogRef.componentInstance.code_categorie_seco = this.incident.code_categorie_seco
    dialogRef.componentInstance.code_type_incident = this.incident.code_type_incident
    dialogRef.componentInstance.code_statut_incident = this.incident.code_statut_incident
    dialogRef.componentInstance.niveau_gravite = this.incident.niveau_gravite
    dialogRef.componentInstance.damage_caused = this.incident.damage_caused
    dialogRef.componentInstance.reasons = this.incident.reasons
    dialogRef.componentInstance.solutions_retenue = this.incident.solutions_retenue
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetData(event)
        this.getIncident();
        this.getHistoriqueIncident();
      }
    })
  }
  AjouterInterventionDialog(){
    const dialogRef = this.dialog.open(AjouterInterventionComponent, {
			width: '800px',
			autoFocus: false,
      maxHeight: '85vh'
		});
    dialogRef.componentInstance.cle_incident = this.incident.cle_incident;
    dialogRef.componentInstance.id_incident = this.incident.id_incident;
    dialogRef.componentInstance.isTypeDisable = true;
    dialogRef.componentInstance.isRapport = false;
    
    dialogRef.componentInstance.code_origin_intervention = 'INCI';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetData(event)
        this.getIncident();
        this.getHistoriqueIncident();
      }
    })
  }

  AjouterRapportDialog(){
    const dialogRef = this.dialog.open(AjouterInterventionComponent, {
			width: '800px',
			autoFocus: false,
      maxHeight: '85vh'
		});
    dialogRef.componentInstance.cle_incident = this.incident.cle_incident;
    dialogRef.componentInstance.id_incident = this.incident.id_incident;
    dialogRef.componentInstance.isTypeDisable = true;
    dialogRef.componentInstance.isCategorieDisable = true;
    dialogRef.componentInstance.isRapport = true;
    dialogRef.componentInstance.code_origin_intervention = 'INCI';
    dialogRef.componentInstance.id_intervention = this.incident.id_intervention_actuelle;


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetData(event)
        this.getIncident();
        this.getHistoriqueIncident();
      }
    })
  }

  openQualifTraitDialog(){
    const dialogRef = this.dialog.open(QualifierTraitementTachesComponent, {
			width: '800px',
			autoFocus: false,
      maxHeight: '85vh'
		});
    dialogRef.componentInstance.cle_incident = this.incident.cle_incident;
    dialogRef.componentInstance.id_incident = this.incident.id_incident;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetData(event)
    this.getHistoriqueIncident();

      }
    })
  }

  imageDetail(url_image: string): void {
    Swal.fire({
      html:`
      <div style="margin-top:20px;">
      <img style="border-radius: 10px;width:100%;height:auto;" src='${url_image}' alt='${url_image}' />
      </div>`,
      // imageUrl: url_image,
      heightAuto: false,
      imageAlt: "image",
      showCloseButton: true,
      focusConfirm: false,
      inputAutoFocus: false,
      showConfirmButton: false,
      width:'900px',
      didRender: () => {
        const closeButton = Swal.getCloseButton();
        if (closeButton) {
          closeButton.setAttribute('tabindex', '-1');
        }
      }
    });
  }
  verifierIncident(item: any) {
    const dialogRef = this.dialog.open(VerifierIncidentComponent, {
      width: "800px",
      autoFocus: false,
      maxHeight: "90vh",
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterOpened().subscribe(() => {
      const dialogContainer = document.querySelector('.custom-dialog-container');
      if (dialogContainer) {
        const dialogContent = dialogContainer.querySelector('.dialog-content');
        if (dialogContent instanceof HTMLElement) {
          dialogContent.style.height = '34vh';
          dialogContent.style.overflow = 'auto';
        }
      }
    });
    
    dialogRef.componentInstance.cle_incident = this.incident.cle_incident;
    dialogRef.componentInstance.id_incident = this.incident.id_incident;
    dialogRef.afterClosed().subscribe(result => {
      this.resetData(event);
      this.getIncident();
      this.getHistoriqueIncident();
    })
  }

  annulerIncident(item: any) {
    Swal.fire({
      title: "Veuillez clarifier le motif d'annulation",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: "Annuler",
      cancelButtonText: "Quitter",
      inputAutoFocus:false,
      inputPlaceholder:'Motif',
      confirmButtonColor: '#D71313',
      showLoaderOnConfirm: true,
      preConfirm: (motif) => {
        Swal.fire({
          title: "Opération est en cours!",
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let body = {
          
          id_incident: item.id_incident,
          id_residence: item.id_residence,
          code_statut_incident: 'ANNU',
          code_etape: this.liste_historique[this.liste_historique.length - 1].code_etape,
          motif_abandon: motif,
        };
        this.listincidentService.annulerIncident(body).subscribe(
          (res) => {
            this.getIncident();
            this.getHistoriqueIncident();
            this.showMessageSuc(`Incident annulé avec succès`);
          },
          (error) => {
            this.showMessageError();
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
}
  showMessageSuc(msg) {
    Swal.fire({
      icon: "success",
      text: msg,
      showConfirmButton: true,
      heightAuto: false,
      timer: 4000,
    });
  }
}
