// The file for the current environment will overwrite this one during build.
// Different environments can be found in ./environment.{dev|prod}.ts, and
// you can create your own and use it with the --env flag.
// The build system defaults to the dev environment.

export const environment = {
  // api_url: "https://resident-api.lokabook.ma/",
  //upload_url: "https://mowatine-api.lokabook.ma/upload/",
  api_url: "https://resident-dev-api.lokabook.ma/",
//   api_url:"https://milkiya-api.lokabook.ma/" ,
  // api_url: "http://localhost:3000/",
  code_logo: "dev",
  googleApiKey: "AIzaSyCaSi4gvnKFKxDO-NexiiflniatdSNMFPw",
  production: false
};
