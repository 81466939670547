import { Injectable } from "@angular/core";
import { CookieStoreService } from "app/service/cookieService/cookieService.service";

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon?: string;
  flag_droits: string;
  children?: ChildrenItems[];
}
import { CurrentUserService } from "app/service/current-user.service";

@Injectable()
export class MenuItems {
  userRole: any = {};
  flag_incident;
  modules : any;
  flag_utilisateurs;
  libelle_occupant_pluriel;
  libelle_site_short;
  flag_genre_site
  constructor(private _currentUser: CurrentUserService,private cookies :  CookieStoreService) {
    this.userRole = this._currentUser.getRoleCurrentUser();
    this.modules = this.cookies.getTOKEN("modules");
    this.libelle_occupant_pluriel = this.cookies.getTOKEN("libelle_occupant_pluriel").replace(/"/g, '');
    this.libelle_site_short = this.cookies.getTOKEN("libelle_site_short").replace(/"/g, '');
    this.flag_genre_site = this.cookies.getTOKEN("flag_genre_site").replace(/"/g, '');
  }

  getAll(): Menu[] {
    this.userRole = this._currentUser.getRoleCurrentUser();

    this.userRole?.code_role_utilisateur == "USER" &&
    this.userRole?.flag_incident == "N"
      ? (this.flag_incident = "N")
      : (this.flag_incident = "O");

    this.userRole?.code_role_utilisateur == "USER" &&
    this.userRole?.flag_utilisateurs == "N"
      ? (this.flag_utilisateurs = "N")
      : (this.flag_utilisateurs = "O");

    let flag_droit_residences = this.modules.includes("MERE") ? "O" : "N",
        flag_droit_incidents = this.modules.includes("INCD") ? "O" : "N",
        flag_droit_tickets = this.modules.includes("DEMA") ? "O" : "N",
        flag_droit_interventions = this.modules.includes("INTR") ? "O" : "N",
        flag_droit_reservtion = this.modules.includes("RESR") ? "O" : "N",
        flag_droit_autorisation = this.modules.includes("AUTR") ? "O" : "N",
        flag_droit_menu_communications = ["ALLE", "NEWS", "IFUT"].some(code => this.modules.includes(code)) ? 'O' : 'N',
        flag_droit_alerte = this.modules.includes("ALLE") ? "O" : "N",
        flag_droit_news = this.modules.includes("NEWS") ? "O" : "N",
        flag_droit_infoUtiles = this.modules.includes("IFUT") ? "O" : "N",
        flag_droit_communications = this.modules.includes("COMU") ? "O" : "N"
        

    const MENUITEMS = [
      {
        state: "dashboard",
        name: "Acceuil",
        type: "link",
        flag_droits: "O",
        icon: "dashboard",
      },
      {
        state: "residence",
        name: this.libelle_site_short,
        type: "sub",
        flag_droits: flag_droit_residences,
        icon: "home",
        children: [
          {
            state: "fiche_residence",
            name: `${this.flag_genre_site == "M" ? "Mon" : "Ma"} ${this.libelle_site_short}`,
            type: "link",
          },
          {
            state: "liste-resident",
            name: this.libelle_occupant_pluriel,
            type: "link",
          },
          {
            state: "liste-utilisateurs",
            name: "Gestionnaires",
            type: "link",
            flag_droits: this.flag_utilisateurs,
            icon: "supervisor_account",
          },
          {
            state: "liste-agents",
            name: "Agents",
            type: "link",
            flag_droits: this.flag_utilisateurs,
            icon: "supervisor_account",
          },
        ],
      },
      {
        state: "incidents/liste-incident",
        name: "Incidents",
        type: "link",
        flag_droits: flag_droit_incidents,
        icon: "report",
      },
      {
        state: "demandes-requetes",
        name: "Demandes",
        type: "link",
        flag_droits: flag_droit_tickets,
        icon: "assignment",
      },
      {
        state: "interventions",
        name: "Interventions",
        flag_droits:flag_droit_interventions,
        type: "sub",
        icon: "build",
        children: [
          {
            state: "liste",
            name: "List des interventions",
            type: "link",
          },
          {
            state: "calendrier",
            name: "Agenda",
            type: "link",
          },
        ]
      },
      {
        state: "reservations",
        name: "Réservations",
        type: "sub",
        flag_droits: flag_droit_reservtion,
        icon: "event",
        children: [
          {
            state: "liste",
            name: "Liste des réservations",
            type: "link",
          },
          {
            state: "calendrier",
            name: "Agenda",
            type: "link",
          },

        ],
      },
      {
        state: "autorisations",
        name: "Invitations",
        type: "sub",
        flag_droits: flag_droit_autorisation,
        icon: "card_membership",
        children: [
          {
            state: "liste",
            name: "Liste des autorisations",
            type: "link",
          },
          {
            state: "historique",
            name: "Historique E/S",
            type: "link",
          }
        ],
      },
	  {
        state: "communications",
        name: "Communication",
        type: "sub",
        flag_droits: flag_droit_menu_communications,
        icon: "chat",
        children: [
          // {
          //   state: "liste_communications",
          //   name: "Communications",
          //   flag_droits : flag_droit_communications,
          //   type: "link",
          // },
          {
            state: "liste-news",
            name: "Actualités",
            flag_droits : flag_droit_news,
            type: "link",
          },
          {
            state: "liste-alerts",
            name: "Alertes",
            flag_droits : flag_droit_alerte,
            type: "link",
		      },
          {
            state: 'liste_info_utiles',
            name: 'Informations utiles',
            type: 'link',
            flag_droits: flag_droit_infoUtiles,
            icon: 'list'
          },
        ],
      },

    ];
    return MENUITEMS;
  }
}
