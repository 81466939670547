import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilisateursRoutingModule } from './utilisateurs-routing.module';
import { ListeUtilisateursComponent } from './liste-utilisateurs/liste-utilisateurs.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { WidgetComponentModule } from 'app/widget-component/widget-component.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';
import { MatRadioModule } from '@angular/material/radio';
import {MatTableDataSource} from '@angular/material/table';
import { AjouterBiensComponent } from './ajouter-biens/ajouter-biens.component';
import { AjouterUtilisateurComponent } from './ajouter-utilisateur/ajouter-utilisateur.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { FicheUtilisateurComponent } from './fiche-utilisateur/fiche-utilisateur.component';
import { ReservationsModule } from 'app/reservations/reservations.module';
import { IncidentModule } from 'app/incident/incident.module';
import { ListeAgentsComponent } from './liste-agents/liste-agents.component';
import { ModifierUtilisateurComponent } from './modifier-utilisateur/modifier-utilisateur.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModifierMetierAgentComponent } from './modifier-metier-agent/modifier-metier-agent.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
@NgModule({
  declarations: [
    ListeUtilisateursComponent,
    AjouterBiensComponent,
    AjouterUtilisateurComponent,
    FicheUtilisateurComponent,
    ListeAgentsComponent,
    ModifierUtilisateurComponent,
    ModifierMetierAgentComponent,
  ],
  imports: [MatSlideToggleModule,
    CommonModule,
    UtilisateursRoutingModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    Ng2TelInputModule,
    NgOtpInputModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDividerModule,
    MatTableModule,
    MatChipsModule,
    WidgetComponentModule,
    MatSortModule,
    MatDialogModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    SlickCarouselModule,
    TranslateModule,
    MatListModule,
    NgbModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    NgxMaskModule,
    MatTabsModule,
    AgmCoreModule,
    MatRadioModule,
    ReservationsModule,
    IncidentModule
  ]
})
export class UtilisateursModule { }
