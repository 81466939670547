import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentRoutingModule } from './incident-routing.module';
import { ListeIncidentComponent } from './liste-incident/liste-incident.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { WidgetComponentModule } from 'app/widget-component/widget-component.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';
import { MatRadioModule } from '@angular/material/radio';
import {MatTableDataSource} from '@angular/material/table';
import { FicheIncidentComponent } from './fiche-incident/fiche-incident.component';
import { QualifIncidentComponent } from './qualif-incident/qualif-incident.component';
import { TraiterIncidentComponent } from './traiter-incident/traiter-incident.component';
import { QualifierTraitementComponent } from './qualifier-traitement/qualifier-traitement.component';
import { AjouterIncidentComponent } from './ajouter-incident/ajouter-incident.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { VerifierIncidentComponent } from './verifier-incident/verifier-incident.component';

@NgModule({
  declarations: [
    ListeIncidentComponent,
    FicheIncidentComponent,
    QualifIncidentComponent,
    TraiterIncidentComponent,
    QualifierTraitementComponent,
    AjouterIncidentComponent,
    VerifierIncidentComponent,
  ],
  exports: [
    ListeIncidentComponent
  ],
  imports: [
    CommonModule,
    IncidentRoutingModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatPaginatorModule,
    MatDividerModule,
    MatTableModule,
    MatChipsModule,
    MatSelectModule,
    WidgetComponentModule,
    MatExpansionModule,
    MatSortModule,
    MatDialogModule,
    MatMenuModule,
    FormsModule,ReactiveFormsModule,
    MatSnackBarModule,
    SlickCarouselModule,
    TranslateModule,
    MatListModule,
    NgbModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    NgxMaskModule,
    MatTabsModule,
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyC3EfyE2EEzIXk4y17fmgc_xEiP_RVBC0U'
    }),
    MatRadioModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatCardModule
  ]
})
export class IncidentModule { }
